import axios from "axios"
import Element from "element-ui";
import tools from "./utils.js";
import qs from "qs"
let url = ''
if (process.env.NODE_ENV == 'development') {
	url = ''
} else if (process.env.NODE_ENV == 'production') {
	url = 'https://tuku.aishangts.com'
}


const service = axios.create({
	baseURL: url,
	timeout: 6000,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
	}
})

service.interceptors.request.use((config) => {
	if (tools.S.get('logindata')) {
		config.headers['token'] = tools.S.get('logindata').token;
		config.headers['uid'] = tools.S.get('logindata').uid;
	}
	config.data = qs.stringify(config.data)
	return config
})
service.interceptors.response.use((response) => {
	if (response.status == 200) {
		if (response.data.code == 400 || response.data.code == 401) {
			Element.Message.error('登录状态失效，请重新登录')
			tools.S.remove('logindata')
			if (process.env.NODE_ENV == 'development') {
				window.location.href = '/login'
			} else if (process.env.NODE_ENV == 'production') {
				window.location.href = '/dist/login'
			}
			
			return Promise.reject(response.data)
		}
		return Promise.resolve(response.data)
	} else if (response.status == 500) {
		Element.Message.error('系统错误，请联系系统管理员')
	}

})

export default service
