import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import tools from "./assets/js/utils.js";
import request from "./assets/js/request.js";
import './assets/css/base.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/font/iconfont.css';
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.S = tools.S
Vue.prototype.request = request

new Vue({
	el:'#app',
	router,
	render: h => h(App),
}).$mount('#app')
