import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login/login.vue";
import index from "../views/index/index.vue";
import search from "../views/search/search.vue";
import list from "../views/list/list.vue";
import NotFound from "../views/NotFound/NotFound.vue";
Vue.use(VueRouter);
const routes = [{
		path: "/",
		name: "login",
		component: login
	},{
		path: "/index",
		name: "index",
		component: index,
		redirect:'/search',
		children: [{
			path: "/search",
			name: "search",
			component: search
		},{
			path: "/list",
			name: "list",
			component: list
		}]
	},
	
	{ path: '*', redirect: '/NotFound', hidden: true }
];

const router = new VueRouter({
	mode: "history",
	base:process.env.BASE_URL,
	routes,
	scrollBehavior: () => ({ y: 0 })
});
router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	next()
})
export default router;
