<template>
	<div id="box">
		<div class="box">
			<div class="main-box">
				<div class="login-box">
					<div class="title">登录</div>
					<div class="input-box">
						<div class="input-item">
							<el-input placeholder="账号" v-model="account">
								<i slot="prefix" class="iconfont icon-zhanghao1"></i>
							</el-input>
						</div>
						<div class="input-item">
							<el-input placeholder="密码"  v-model="password" :type="isSee?'text':'password'">
								<i slot="prefix" class="iconfont icon-mima1"></i>
								<i slot="suffix" :class="isSee?'iconfont icon-zhengyan-21 pointer':'iconfont icon-biyan_xian pointer'" @click="isSee = !isSee"></i>
							</el-input>
						</div>
						<el-button class="submit-btn" type="primary" @click="login()">登录</el-button>
						<!-- <div class="label">
							<div :class="ischeck?'iconfont icon-fenzu1':'iconfont icon-weixuanzhongyuandian1'"></div>
							<div class="label-text">我已阅读并同意<span class="inner">《用户协议》</span></div>
						</div> -->
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		name: "login",
		data() {
			return {
				account:'',
				password:'',
				isSee:false,
			};
		},
		created(){
			document.title='爱尚实物图'
		},
		methods:{
			login(){
				if(this.account == ''){
					this.$message.error('请输入账号')
					return false
				}else if(this.password==''){
					this.$message.error('请输入密码')
					return false
				}
				this.request({
					url: 'index/Publics/login',
					method: 'post',
					data: {
						account:this.account,
						password:this.password
					}
				}).then((res)=>{
					if(res.status){
						this.$message.success(res.msg);
						this.S.set('logindata',res.data)
						setTimeout(()=>{
							this.$router.push({
								path:'/index'
							})
						},1500)
						
					}else{
						this.$message.error(res.msg)
					}
				})
			}
		}
		
	}
</script>

<style scoped lang="scss">
	/deep/.el-input__prefix{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	/deep/.el-input__suffix{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	/deep/.el-input__inner{
		height:48px;
		line-height: 48px;
	}
	.icon-zhengyan-21{
		font-size: 12px;
		padding-right: 10px;
	}
	.icon-biyan_xian{
		font-size: 12px;
		padding-right: 10px;
	}
	.box {
		width: 100%;
		height: 100%;
		background: url('../../assets/image/bg.png') center center no-repeat;
		background-size: cover;
		.main-box{
			width: 1200px;
			height: 100%;
			margin: 0 auto;
			position: relative;
		}
		.login-box{
			width: 490px;
			height: 400px;
			background-color: #fff;
			border-radius: 10px;
			box-shadow: 0px 0px 20px 2px rgba(56, 139, 255, 0.5);
			position: absolute;
			right: 0;
			top: calc((100% - 400px) / 2);
			.title{
				text-align: center;
				font-size: 18px;
				font-weight: 500;
				color: #333333;
				padding:37px 0 30px 0;
			}
			.input-box{
				width: 360px;
				margin: 0 auto;
				.input-item{
					width: 100%;
					margin-bottom: 15px;
					height: 48px;
				}
			}
			.submit-btn{
				width: 360px;
				height: 48px;
				margin-top: 30px;
			}
			.label{
				display: flex;
				align-items: center;
				margin-top: 20px;
				color: #999999;
				.iconfont{
					font-size: 14px;
					&.icon-fenzu1{
						color: #1989FA;
					}
				}
				.label-text{
					padding-left: 2px;
					.inner{
						color: #1989FA;
					}
				}
			}
		}
	}
</style>
