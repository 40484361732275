<style scoped>
	.bgs {
		z-index: 999;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.masked {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: .5;
		background: #000;
		z-index: -1;
	}

	.img-content {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 9;
	}

	.img-content img {
		max-width: 100%;
		max-height: 100%;
		display: block;
		margin: auto;
		position: relative;
	}

	.btns {
		position: absolute;
		z-index: 99999;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		user-select: none;
		width: 44px;
		height: 44px;
		font-size: 24px;
		background-color: #606266;
		border-color: #fff;
		font-weight: 800;
		color: rgb(139, 139, 140);
	}

	.left-btn {
		left: 40px;
		top: 50%;
	}

	.right-btn {
		right: 40px;
		top: 50%;
	}

	.close-btn {
		top: 40px;
		right: 40px;
	}

	.btns.bottom-content {
		left: 50%;
		bottom: 30px;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 282px;
		height: 44px;
		padding: 0 23px;
		background-color: #606266;
		border-color: #fff;
		border-radius: 22px;
		justify-content: space-around;
	}

	.item-btn {
		color: #fff;
		font-size: 23px;
	}
	.video{
		width: 960px;
		height: 590px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
</style>
<template>
	<div class="bgs" @mousewheel.prevent="wheel">
		<div class="masked"></div>
		<div class="img-content" v-if="type == 0">
			<img :src="src" alt="" ref="img" @mousedown="move"
				:style="{'transform':' scale('+scale+') rotate('+rotate+'deg)'}">
		</div>
		<div v-else>
			<video :src="src" controls class="video"></video>
		</div>
		<div class="left-btn btns"  @click="pre()"><i class="el-icon-arrow-left"></i></div>
		<div class="right-btn btns" @click="next()"><i class="el-icon-arrow-right"></i></div>
		<div class="close-btn btns" @click="close()"><i class="el-icon-close"></i></div>
		<div class="bottom-content btns" v-if="type == 0">
			<div class="item-btn" @click="zoomOut()"><i class="el-icon-zoom-out"></i></div>
			<div class="item-btn" @click="zoomIn()"><i class="el-icon-zoom-in"></i></div>
			<div class="item-btn" @click="full()"><i v-if="isFull" class="el-icon-full-screen"></i><i v-else
					class="el-icon-c-scale-to-original"></i></div>
			<div class="item-btn" @click="turnLeft()"><i class="el-icon-refresh-left"></i></div>
			<div class="item-btn" @click="turnRight()"><i class="el-icon-refresh-right"></i></div>
		</div>
	</div>
</template>

<script>
	module.exports = {
		data: function() {
			return {
				scale: 1,
				rotate: 0,
				src: '',
				isFull: true,
				imgList: [],
				index: 0,
				type: 1
			}
		},
		mounted() {

			document.addEventListener('keyup', (e) => {
				if (e.keyCode == 27) {
					this.close()
				} else if (e.keyCode == 37) {
					this.pre()
				} else if (e.keyCode == 39) {
					this.next()
				}
			})

		},
		methods: {
			move(e) {
				e.preventDefault();
				e.stopPropagation()
				let odiv = e.target; //获取目标元素
				//算出鼠标相对元素的位置
				console.log(e)
				let disX = e.clientX - odiv.offsetLeft;
				let disY = e.clientY - odiv.offsetTop;
				document.onmousemove = (e) => { //鼠标按下并移动的事件
					//用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
					let left = e.clientX - disX;
					let top = e.clientY - disY;
					odiv.style.marginLeft = left + 'px';
					odiv.style.marginTop = top + 'px';
				};
				document.onmouseup = (e) => {
					document.onmousemove = null;
					document.onmouseup = null;
				};
			},
			wheel(e) {
				let delta = (e.wheelDelta && (e.wheelDelta > 0 ? 1 : -1))
				if (delta > 0) { //放大
					// 向上滚
					this.zoomIn()
				} else if (delta < 0) { //缩小
					this.zoomOut()
				}
			},
			close() {
				if (this.$parent.$refs.img) {
					this.$parent.showImage = false
				} else {
					this.$parent.$parent.showImage = false
				}

				this.imgList = []
				this.index = 0
				this.clear()
			},
			open(index, list) {
				this.imgList = list
				this.index = index
				this.type = this.imgList[this.index].type
				this.src = this.imgList[this.index].url
			},
			full() {
				this.scale = 1
				this.isFull = !this.isFull
			},
			clear() {
				this.scale = 1
				this.rotate = 0;
				
			},
			pre() {
				
				if (this.index >= 1) {
					this.clear()
					this.index = this.index - 1
					this.type = this.imgList[this.index].type
					this.src = this.imgList[this.index].url
				} else {
					this.$parent.error('已经是第一张啦')
					this.index = 0
				}
			},
			next() {
				
				if (this.index < this.imgList.length - 1) {
					this.clear()
					this.index = this.index + 1
					this.type = this.imgList[this.index].type
					this.src = this.imgList[this.index].url
				} else {
					this.$parent.error('已经是最后一张啦')
					this.index = this.imgList.length - 1
				}

			},
			zoomOut() {
				if (this.scale <= 0.2) {
					this.scale = 0.2
				} else {
					this.scale = this.scale - 0.2
				}
			},
			zoomIn() {
				this.scale = this.scale + 0.2
			},
			turnLeft() {
				this.rotate = this.rotate - 90
			},
			turnRight() {
				this.rotate = this.rotate + 90
			}
		}
	}
</script>
