<template>
	<div id="box">
		<div class="mainBox">
			<div class="top">
				<div :class="isAll?'top-item active pointer actived':'top-item active pointe'" @click="checkAll()">全选
				</div>
				<div class="top-item pointer" @click="download()">批量下载</div>
				<div class="top-item pointer" @click="delt()">批量删除</div>
			</div>
			<div class="list-box" v-loading="loading">
				<el-popover v-for="(item,index) in list" :key="item.id" placement="bottom-end" width="224"
					trigger="hover">
					<div class="popover-content">
						<div class="popover-item pointer" @click="look(index)">查看</div>
						<div class="popover-item pointer" @click="downloads(index)">下载</div>
						<div class="popover-item pointer" style="border-bottom: none;" @click="delts(item.id)">删除</div>
					</div>
					<div slot="reference"
						:class="[arr.indexOf(item.id)==-1?'':'active','list-items pointer',(index+1)%4==0?'actived':'']"
						@click="select(index)">
						<el-image class="image"
							:src="item.type == 1?item.url+'?x-oss-process=video/snapshot,t_0,f_png,w_0,h_0,m_fast':item.url"
							lazy fit="contain"></el-image>
						<img class="play-btn" v-if="item.type == 1" src="../../assets/image/play.png" alt="">
					</div>
				</el-popover>

			</div>
		</div>
		<imgview v-show="showImage" ref="img"></imgview>
	</div>
</template>

<script>
	import imgview from "../../components/imageView.vue";
	import download from "downloadjs";
	import JSZip from 'jszip'
	import FileSaver from 'file-saver'
	import axios from 'axios'
	export default {
		name: 'list',
		data() {
			return {
				loading: true,
				code: '',
				list: [],
				arr: [],
				isAll: false,
				showImage: false
			};
		},
		components: {
			imgview
		},
		methods: {
			delts(e) {
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.request({
						url: 'index/user/del',
						method: 'post',
						data: {
							id: e
						}
					}).then((res) => {
						if (res.status) {
							this.$message.success(res.msg);
							this.list.splice(this.list[this.arr.indexOf(e)], 1)
						} else {
							this.$message.error(res.msg)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			delt() {
				if (this.arr.length == 0) {
					this.$message.error('请先勾选需要删除的图片或视频')
					return false
				} else {
					this.$confirm('是否确认删除?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.request({
							url: 'index/user/del',
							method: 'post',
							data: {
								id: this.arr.join(',')
							}
						}).then((res) => {
							if (res.status) {
								this.$message.success(res.msg);
								for (let i = this.list.length - 1; i >= 0; i--) {
									for (let x = this.arr.length - 1; x >= 0; x--) {
										if (this.list[i].id == this.arr[x]) {
											this.list.splice(i, 1)
											this.arr.splice(x, 1)
										}
									}
								}
							} else {
								this.$message.error(res.msg)
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}

			},
			download() {
				if (this.arr.length == 0) {
					this.$message.error('请勾选需要下载的内容')
					return false
				} else {
					this.$confirm('是否确认批量下载选中的图片或视频?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let promises = [];
						const getFile = url => {
							return new Promise((resolve, reject) => {
								const obj = {
									method: 'get',
									url,
									responseType: 'arraybuffer'
								}
								try {
									axios(obj).then((res)=>{
										resolve(res.data)
									})
								} catch (error) {
									reject(error.toString())
								}
							})
						}
						
						const data = [] // 文件路径数组
						for(let x=0;x<this.list.length;x++){
							for(let i=0;i<this.arr.length;i++){
								if(this.list[x].id == this.arr[i]){
									data.push(this.list[x])
								}
							}
						}
						const zip = new JSZip()
						const cache = {}
						data.forEach((item, index) => {
							const promise = getFile(item.url).then(res => {
								const file_name = item.name // 获取文件名
								zip.file(file_name, res, {
									binary: true
								})
								cache[file_name] = res
							})
							promises.push(promise)
						})
						Promise.all(promises).then(() => {
							zip.generateAsync({
								type: 'blob'
							}).then(content => {
								FileSaver.saveAs(content,`${this.code}.zip`) // 利用file-saver保存文件  自定义文件名
								this.$message.success('下载完成');
							})
						}).catch((err)=>{
							this.$message.success('下载失败');
						})	
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}
			},
			downloads(e) {
				fetch(this.list[e].url)
					.then(response => response.blob())
					.then(res => {
						download(res, this.list[e].name)
					});
			},
			look(e) {
				this.$refs.img.open(e, this.list)
				this.showImage = true
			},
			error(msg) {
				this.$message.error(msg)
			},
			checkAll() {
				this.isAll = !this.isAll
				if (this.arr.length == this.list.length) {
					this.arr = []
				} else {
					for (let i = 0; i < this.list.length; i++) {
						if (this.arr.indexOf(this.list[i].id) == -1) {
							this.arr.push(this.list[i].id)
						}
					}
				}
			},
			select(e) {
				if (this.arr.indexOf(this.list[e].id) == -1) {
					this.arr.push(this.list[e].id)
				} else {
					this.arr.splice(this.arr.indexOf(this.list[e].id), 1)
				}
				if (this.arr.length == this.list.length) {
					this.isAll = true
				} else {
					this.isAll = false
				}
			},
			getList() {
				this.request({
					url: 'index/user/pcList',
					method: 'post',
					data: {
						code: this.code
					}
				}).then((res) => {
					this.loading = false
					if (res.status) {
						this.list = res.data
					} else {
						this.$message.error(res.msg)
					}
				})
			}
		},
		created() {
			document.title = '爱尚实物图'
			this.code = this.$route.query.code
			this.getList()
		}


	}
</script>

<style scoped lang="scss">
	.popover-content {
		padding: 0;
		width: 100%;
		height: auto;

		.popover-item {
			width: 100%;
			height: 48px;
			line-height: 48px;
			border-bottom: 1px solid #E7E7E7;
			text-indent: 18px;
			font-size: 15px;
			color: #333333;

			&:hover {
				color: #1989FA;
			}
		}
	}

	.mainBox {
		position: relative;

		.top {
			width: 100%;
			height: 68px;
			display: flex;
			align-items: center;
			position: absolute;
			z-index: 99;
			top: 0;
			left: 0;

			.top-item {
				width: 56px;
				margin-right: 40px;
				font-size: 14px;
				color: #333333;

				&.active {
					margin-right: 12px;
				}

				&.actived {
					color: #1989FA;
				}
			}
		}

		.list-box {
			width: 100%;
			height: auto;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-top: 68px;

			.list-items {
				width: 277px;
				height: 188px;
				border-radius: 6px;
				margin: 0 28px 28px 0;
				border: 1px solid #DDDDDE;
				position: relative;

				&.actived {
					margin-right: 0;
				}

				&.active {
					border: 1px solid #1989FA;
				}

				.image {
					width: 100%;
					height: 100%;
					border-radius: 6px;
				}

				.play-btn {
					position: absolute;
					width: 40px;
					height: auto;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
</style>
