<template>
	<div>
		<div class="top">
			<div class="mainBox">
				<div class="main-top-l pointer" @click="goHome()">
					<img src="../../assets/image/index.png" alt="">
				</div>
				<div class="main-top-r pointer" @click="isPop=!isPop">
					<div :class="isPop?'account-box active':'account-box'" slot="reference">
						<div class="account">{{userinfo.account}}</div>
						<img class="tr-down" v-if="!isPop" src="../../assets/image/down.png" alt="">
						<img class="tr-down" v-else src="../../assets/image/down_ed.png" alt="">
					</div>
					<ul :class="isPop?'pop-box active':'pop-box'">
						<li class="pop-item pointer">{{userinfo.name}}</li>
						<li class="pop-item pointer" @click="change()">修改密码</li>
						<li class="pop-item pointer" @click="loginout()">退出登录</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="main-box">
			<transition name="fade">
				<router-view></router-view>
			</transition>
		</div>
		<el-dialog title="" top="25vh" :show-close="false" :visible.sync="dialogVisible" width="35%">
			<div class="dialog-title" slot="title">
				<div class="title-text">修改密码</div>
				<div class="iconfont icon-chakantupiande pointer" @click="dialogVisible=false"></div>
			</div>
			<div class="dialog-main">
				<div class="input-box">
					<div class="input-item">
						<el-input placeholder="新密码" type="text" v-model="password"></el-input>
					</div>
					<div class="input-item">
						<el-input placeholder="重复密码" type="text" v-model="repassword"></el-input>
					</div>
				</div>
				<el-button class="confirm-btn" type="primary" @click="confirm()">确认</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "index",
		data() {
			return {
				dialogVisible:false,
				isPop: false,
				userinfo: {
					name: '',
					account: ''
				},
				password:'',
				repassword:''
			};
		},
		methods: {
			goHome(){
				if(this.$route.path!='/search'){
					this.$router.push({
						path:'/search'
					})
				}
			},
			confirm(){
				if(this.password == ''){
					this.$message.error('请输入新密码')
					return false
				}else if(this.repassword != this.password){
					this.$message.error('两次输入密码不一致')
					return false
				}
				this.$confirm('是否确认修改密码?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.request({
						url: 'index/User/setPass',
						method: 'post',
						data: {
							password:this.password,
							rePass:this.repassword
						}
					}).then((res)=>{
						if(res.status){
							this.$message.success(res.msg);
							this.S.remove('logindata')
							setTimeout(()=>{
								this.$router.push({
									path:'/'
								})
							},1500)
						}else{
							this.$message.error(res.msg)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			loginout() {
				this.$confirm('是否确认退出登录?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.S.remove('logindata')
					this.$message({
						type: 'success',
						message: '退出成功!'
					});
					setTimeout(()=>{
						this.$router.push({
							path:'/'
						})
					},1500)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			change(){
				this.dialogVisible = true
			}
		},
		created() {
			document.title='爱尚实物图'
			if (!this.S.get('logindata')) {
				this.$message({
					message: '登录已过期，请重新登录',
					type: 'error'
				});
				setTimeout(() => {
					this.$router.push({
						path: '/login'
					})
				}, 1500)
			} else {
				this.userinfo = this.S.get('logindata')
				this.userinfo.account = this.userinfo.account.substring(0, 3) + '****' + this.userinfo.account.substring(7)
			}
		}
	}
</script>

<style scoped lang="scss">
	.top {
		width: 100%;
		height: 60px;
		background: #1989FA;
		z-index: 99;

		.mainBox {
			width: 1200px;
			display: flex;
			align-items: center;
			margin: 0 auto;
			justify-content: space-between;
			height: 100%;

			.main-top-l {
				height: 100%;
				line-height: 60px;

				img {
					width: 20px;
					height: auto;
				}
			}

			.main-top-r {
				width: 150px;
				height: 100%;
				position: relative;
				z-index: 999;

				.account-box {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: space-around;
					font-size: 14px;
					color: #fff;

					&.active {
						background: #fff;
						border: 1px solid #E7E7E7;
						border-bottom: none;
						color: #1989FA;
					}

					.tr-down {
						width: 8px;
						height: auto;
					}
				}

				.pop-box {
					width: 150px;
					height: 132px;
					background: #fff;
					position: absolute;
					bottom: 0;
					transition: transform .3s ease-in-out;
					opacity: 0;
					z-index: -1;
					transform: translate3d(0, 0, 0);

					&.active {
						height: 132px;
						border: 1px solid #E7E7E7;
						opacity: 1;
						border-top: none;
						transform: translate3d(0, 152px, 0);
					}

					.pop-item {
						width: 100%;
						height: 44px;
						text-align: center;
						overflow: hidden;
						white-space: nowrap;
						width: 95%;
						margin: 0 auto;
						font-size: 14px;
						color: #333333;
					}
				}


			}
		}
	}
	/deep/.el-dialog__header{
		padding: 0;
		width: 100%;
		height: 72px;
		background-color: #F7F7F7;
	}
	.dialog-title{
		text-align: center;
		height: 72px;
		line-height: 72px;
		font-size: 20px;
		color: #303133;
		position: relative;
		.iconfont{
			position: absolute;
			width: 72px;
			height: 72px;
			line-height: 72px;
			text-align: center;
			font-size: 18px;
			top: 0;
			right: 0;
		}
	}
	/deep/.el-input__inner{
		height:48px;
		line-height: 48px;
	}
	.dialog-main{
		width: 75%;
		margin: 0 auto;
		padding: 33px 0 47px 0;
		.input-box{
			width: 100%;
			.input-item{
				margin-bottom: 20px;
			}
		}
		.confirm-btn{
			width: 100%;
			height: 48px;
			margin-top: 10px;
		}
	}
</style>
