<template>
	<div id="box">
		<div class="mainBox">
			<div class="title">条形码查询</div>
			<div class="input-box">
				<el-input placeholder="请输入条形码" v-model="code">
					<template slot="append" ><div class="btn" @click="search()">查询</div></template>
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'search',
		data() {
			return {
				code: ''
			};
		},
		created(){
			document.title='爱尚实物图'
		},
		methods: {
			search() {
				if (this.code == '') {
					this.$message.error('请输入条形码')
					return false
				}
				this.$router.push({
					path: '/list',
					query: {
						code: this.code
					}
				})
			}
		}

	}
</script>

<style scoped lang="scss">
	.title {
		text-align: center;
		padding-top: 300px;
		font-size: 28px;
		color: #1989FA;
	}
	.btn{
		width: 100%;
		height: 50px;
		line-height: 50px;
	}
	.input-box {
		width: 650px;
		margin: 55px auto;
	}

	/deep/.el-input__inner {
		height: 50px;
		line-height: 50px;
	}

	/deep/.el-input-group__append {
		padding: 0;
		width: 108px;
		color: #fff;
		background: #1989FA;
		cursor: pointer;
		text-align: center;
	}

	/deep/.el-input-group__append:hover {
		background: rgba(25, 137, 250, .6)
	}
</style>
